@import "src/resource/style/variables";

.weekDays {
  align-self: flex-end;
}

.days {
  margin-top: 8px;
  padding: 2px;
  border-radius: 6px;
  display: flex;
  gap: 2px;
  background-color: $bg-color-secondary-content-2;
  max-width: fit-content;

  .day {
    border: none;
    width: 90px;
    box-shadow: none;

    &:not(.selectedDay):not(:hover) {
      background-color: transparent;
    }
  }
}

.currentDay {
  border: 1px solid $primary-color;
}

.filled {
  position: relative;

  &:after {
    content: '✔';
    color: $primary-color;
    position: absolute;
    top: -3px;
    right: 1px;
    font-size: 16px;
    font-family: Arial, sans-serif;
  }
}
