$grey-1: rgb(233, 233, 233);
$grey-2: rgb(201, 201, 201);
$grey-3: rgb(159, 159, 159);
$grey-4: rgb(114, 114, 114);
$grey-5: rgb(71, 71, 71);
$grey-6: rgb(31, 31, 31);
$grey-7: rgb(26, 26, 26);
$grey-8: rgb(22, 22, 22);
$grey-9: rgb(18, 18, 18);
$grey-10: rgb(14, 14, 14);

$white: #fff;
$error-color: #ff4d4f;
$success-color: #52C41A;

$primary-color: rgb(31 31 31);
$primary-bg-active-color: #5E5E5E;
$secondary-color: #ffeb3b;
$color-text: rgba(0, 0, 0, 0.88);
$color-secondary-text: rgba(0, 0, 0, 0.65);
$color-line: rgba(0, 0, 0, 0.06);
$color-text-light-solid: #fff;
$color-primary-hover: #626262;
$color-secondary-text-2: #595959;
$bg-color-orange: #FCE000;
$bg-color-orange-2: #FEF9CC;

$neutral-separator-lines: #F5F7F9;
$neutral-secondary: #8C8C8C;
$bg-color-content: #F5F5F5;
$bg-color-secondary-content: #fafafa;
$bg-color-secondary-content-2: #F5F5F5;
$bg-color-secondary-content-3: #E6E5E5;
//$border-color: #D9D9D9;
$border-color: rgba(0, 0, 0, 0.15);
$control-item-bg-hover: rgba(0, 0, 0, 0.04);
