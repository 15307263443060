@import "src/resource/style/variables";

.imageWrap {
  width: 164px;
  height: 164px;
  border-radius: 6px;
  border: 1px solid $border-color;
  position: relative;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;

  &.noImage {
    background-color: $control-item-bg-hover;
  }

  img {
    padding: 8px;
    object-fit: contain;
    filter: brightness(80%);
  }

  span {
    font-size: 24px;
    color: $grey-4;
  }
}
