.fieldGrid {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 24px;
  margin-bottom: 12px;

  .fields {
    display: grid;
    grid-gap: 12px;
    grid-auto-flow: row;

    > div:last-child {
      margin: 16px 0;
    }
  }
}
