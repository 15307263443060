@import "src/resource/style/variables";

.container {
  padding: 24px;
  border-radius: 8px;
  background-color: $bg-color-secondary-content;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 24px;
  justify-content: flex-start;
  margin-bottom: 24px;

  .filter {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 8px;
  }
}
