.wrap {
  background-color: transparent;
  box-shadow: none;
  border: none;

  > div {
    padding: 0 !important;
  }

  .empty {
    background-color: #fff;
    border-radius: 6px;
    padding: 24px;
    border: 1px solid #f0f0f0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }
}
