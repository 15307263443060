@import "src/resource/style/variables";

.wrap {
  text-align: center;
  color: $color-secondary-text-2;
  padding: 24px;

  > a {
    color: $color-secondary-text-2;
  }
}
