.table {
  overflow-x: auto;
}

.filterCollapse {
  display: flex;
  align-items: center;

  & > span {
    font-size: 12px;
    margin-left: 5px;
  }
}
