@import "src/resource/style/variables";

.fieldsWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.fieldGrid {
  display: grid;
  grid-template-columns: 1fr 32px;
  gap: 24px;
}

.addBtn {
  max-width: 200px;
  margin-bottom: 12px;
}

.bannerBlock, .audioBlock {
  height: calc(100% - 24px);
}

.audioBlock {
  grid-template-columns: 1fr auto;
  display: grid;
  gap: 16px;
  justify-content: space-between;
  align-items: stretch;

  & > :nth-child(2) {
    justify-content: flex-end;
  }

  & > :nth-child(3) {
    grid-column: span 2;
  }
}
