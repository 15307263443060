.imagesWrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.modal {
  &.empty {
    height: 80%;

    > div,
    > div > div {
      width: 100%;
      height: 100%;
    }

    .imagesWrap,
    .modalContent {
      height: 100%;
    }
  }

  > div { // moved to .modal-content-centered class
    width: max-content;
    max-width: 100%;
    background-color: transparent !important;
    padding: 0 !important;
    margin: 0 auto;
    box-shadow: none !important;
  }

  .modalContent {
    overflow: hidden auto;
    max-height: 94vh;

    > video {
      display: block;
      height: auto;
      width: 100%;
      max-height: 94vh;
    }
  }
}

.count {
  color: #fff;
  font-size: 16px;
  margin-top: 18px;
  text-align: center;
}
