@import "src/resource/style/variables";

.wrap {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  &.disabled {
    opacity: .7;
    pointer-events: none;
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .actions {
      display: grid;
      grid-template-columns: 100px auto 100px;
      grid-gap: 24px;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .allow {
    background-color: $success-color !important;
    border-color: $success-color;
    box-shadow: none;

    &:hover {
      opacity: .8;
    }
  }
}
