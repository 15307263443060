@import 'src/resource/style/variables';

.layout {
  max-width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  background-color: $bg-color-content;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 64px;
    padding: 0 8px;

    .logoSvg {
      width: 100%;
      max-width: 162px;
      height: 32px;
      object-fit: contain;
    }

    &.collapsed {
      .logoSvg {
        height: 28px;
      }
    }
  }

  .siderMenu {
    height: calc(100% - 64px);
    overflow: hidden;

    &.collapsed {
      //display: none;
    }
  }

  .sider {
    position: relative;
    z-index: 3;
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
    background-color: #fff;
    box-shadow: inset -1px 0px 0px #edf0f3;

    &.absolute {
      position: absolute;
    }

    .btn {
      position: absolute;
      top: 50px;
      right: -12px;
      z-index: 10;
      width: 24px;
      height: 24px;
      background: #ffffff;
      border-radius: 50%;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
      transition: 0.1s;
      display: flex;
      align-items: center;
      justify-content: center;

      &.collapsed {} //box-shadow: none;
      .collapseIcon {
        font-size: 12px;
        transition: all .3s;
      }
    }

    .logoutWrapper {
      border-top: 1px solid $border-color;
      padding: 0;
      position: fixed;
      left: 0;
      width: 224px;
      bottom: 0;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

      &:hover {
        background-color: rgba(0, 0, 0, 0.06);
      }
    }

    .logout {
      width: 100%;
      height: 54px;
      text-align: left;
      padding: 4px 24px;
      background-color: transparent !important;
      font-family: 'Open Sans', sans-serif;
      justify-content: start;

      &:hover {
        //color: $color-primary-hover;
      }
    }

    .collapsedLogout {
      &.logoutWrapper {
        width: 48px;
      }
      .logout {
        padding: 4px 16px;
      }
    }
  }
}

.header {
  padding: 0;

  &.headerLogo {
    display: grid;
    flex-wrap: nowrap;
    grid-template-columns: auto 1fr;

    .logo {
      width: 224px;
      border-right: solid 1px #edf0f3;

      &.tablet {
        width: 175px;
      }
    }
  }
}

.wrapper {
  position: relative;
  background-color: $bg-color-content;

  &.tablet {
    margin-left: 48px;
  }

  .scroll {
    position: relative;
    width: 100%;
    max-width: 100%;
    max-height: 100vh;
    overflow: auto;

    $header-height: 64px;
    &.withHeader {
      max-height: calc(100vh - $header-height);

      .content {
        min-height: calc(100vh - $header-height - 16px * 2);
      }
    }

    .content {
      position: relative;
      box-sizing: border-box;
      min-height: calc(100vh - 16px * 2);
      margin: 16px 24px;
      border-radius: 8px;

      &.transparent {
        background: transparent;
      }

      &.minWidth {
        min-width: 920px;
      }
    }
  }
}
