.uploader {
  .upload {}
}

.fileList {
  margin-top: 16px;

  .file {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    .title {
      display: flex;
      gap: 8px;
    }
  }
}
