.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  padding-top: 16px;
  padding-bottom: 16px;

  .pageTitle {
    margin-bottom: 0;
  }

  .extra {}
}
