@import "src/resource/style/variables";

.filesUpload {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  border: 1px solid $border-color;
  border-radius: 8px;
  padding: 12px 16px;

  margin-bottom: 16px;
}
