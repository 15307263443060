@import "src/resource/style/variables";

.wrap {
  display: grid;
  grid-auto-flow: row;
  margin-bottom: 12px;

  .head {
    display: grid;
    justify-content: flex-start;
    grid-gap: 8px;
    grid-template-columns: auto 1fr;
    align-items: center;

    .title {
      font-size: 12px;
      color: $color-secondary-text;
    }
  }
}

.starsWrap {
  display: flex;
  justify-content: flex-start;
}
