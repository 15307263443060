@import "src/resource/style/variables";

.titleWrap {
  margin-bottom: 24px;

  .label {
    margin-bottom: 8px;
  }
}

.htmlContainer {
  background-color: $bg-color-secondary-content-3;
  padding: 40px 15px;
  border-radius: 6px;
}

