@import "src/resource/style/variables";

.wrap {
  margin-top: 24px;
}

.selectedDate {
  display: flex;
  gap: 6px;
  justify-content: center;
  min-width: 128px;
  margin-bottom: 12px;
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  width: max-content;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  box-shadow: none;
  cursor: not-allowed;
}
