.editSubscribe {
  display: flex;
  gap: 15px;
  align-items: center;

  button {
    padding: 0 10px 0 0;
    height: auto;
  }
}

.editSubscribeForm {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  align-items: center;
}
