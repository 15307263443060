$margin-bottom: 28px;

.title {
  margin-top: 0;
}

.secondary {
  display: block;
  margin-bottom: $margin-bottom !important;
  letter-spacing: -0.6px;
}

.email {
  font-weight: 600;
  display: block;
}

.resendEmail {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  margin-bottom: $margin-bottom;

  button {
    padding: 0;
  }
}
