.loading {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
}

.absolute {
  background: rgba(255, 255, 255, 0.5);
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.transparentBg {
  background: transparent;
}
