@import "src/resource/style/variables";

.quill.quill-customized {
  .ql-toolbar {
    border-radius: 6px 6px 0 0;
    background-color: $grey-1;
    border-bottom: none;
  }
  .ql-container {
    border-radius: 0 0 6px 6px;

    .ql-editor {
      min-height: 124px;
    }
  }
  .ql-toolbar, .ql-container {}
}

.ant-form-item-has-error {
  .quill { // .quill-with-error
    .ql-toolbar {
      border-bottom: 1px solid red;
    }
    .ql-container {
      border-left: 1px solid red;
      border-right: 1px solid red;
      border-bottom: 1px solid red;
    }
  }
}

.quill-customized {
  ul li::before {
    color: #FCE000 !important;
    content: '\25CF' !important;
    //padding-right: 6px;
  }
}
