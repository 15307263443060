.fieldGrid {
  display: grid;
  grid-template-columns: 1fr 32px;
  gap: 24px;
}

.addBtn {
  max-width: 200px;
  margin-bottom: 12px;
}

.modalScrollContent {
  max-height: calc(98vh - 64px - 64px - 24px - 28px);
  overflow-y: auto;
}
