.container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.thumbnail {
  max-width: 140px;
  height: 46px;
  object-fit: contain;

  &.small {
    max-width: 46px;
    object-fit: cover;
  }
}
