@import "src/resource/style/variables";

.training {
  padding-bottom: 24px;
  width: 100%;

  .content {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 24px;
  }

  .actions {
    display: grid;
    grid-gap: 12px;
    justify-content: flex-end;
    grid-template-columns: repeat(2, auto);
  }

  .inner {
    align-self: flex-end;
    display: grid;
    grid-auto-flow: row;
    grid-gap: 12px;
    padding: 12px 0;

    .additionalInfo {
      display: grid;
      gap: 12px;
      grid-template-columns: repeat(3, 1fr) 80px;
    }

    input, span {
      color: $color-text;
    }
  }

  &:last-child {
    padding-bottom: 0;

    &:after {
      content: '';
      display: block;
      height: 1px;
      margin: 25px 0;
      background-color: $color-line;
    }
  }
}
