@import "src/resource/style/variables";

.thumbnail {
  width: 256px;
  min-width: 256px;
  min-height: 144px;
}

.iconCentered {
  color: $white;
  font-size: 32px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.container {
  border-radius: 6px;
  border: 1px solid $border-color;
}

.linkContainer {
  @extend .container;
  position: relative;
  background-color: $white;

  &:hover {
    background-color: $control-item-bg-hover;
  }

  .link {
    > img {
      padding: 8px;
      object-fit: contain;
      filter: brightness(80%);
    }

    .duration {
      position: absolute;
      right: 12px;
      bottom: 12px;
      padding: 2px 6px;
      border-radius: 8px;
      background-color: rgba(0, 0, 0, 0.4);
      color: $white;
      font-size: 12px;

      &:hover {
        color: $white;
      }
    }
  }
}

.noLink {
  @extend .container;
  position: relative;
  height: 152px;
  background-color: $control-item-bg-hover;

  .iconCentered {
    color: $grey-4;
  }
}
