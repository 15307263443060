@import "src/resource/style/variables";

.wrap {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(5, auto);

  .media {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-radius: 6px;
    overflow: hidden;
    background-color: $bg-color-content;
    align-items: center;
    justify-content: center;
    height: 160px;
    width: 240px;

    cursor: pointer;

    > div {
      height: 100%;

      > img {
        height: 100%;
        object-fit: cover;
      }
    }

    > span {
      opacity: .45;
      font-size: 36px;
      display: block;
    }
  }

  .videoThumb {
    background-color: $bg-color-content;
    border-radius: 6px;
    overflow: hidden;
    height: 160px;
    position: relative;
    width: 117px;

    .playIcon {
      position: absolute;
      color: #fff;
      top: 50%;
      font-size: 36px;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    & video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.mediaThumbnailContainer {
  position: relative;
}

.editButton {
  position: absolute;
  right: 6px;
  top: 6px;
  font-size: 16px;
  line-height: 16px;
  padding: 3px;
  border-radius: 6px;
  z-index: 100;

  color: black;
  background-color: rgba(255, 255, 255, 0.3);

  &:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }
}

.bannerBlock, .audioBlock {
  height: calc(100% - 24px);
}

