// generate by https://transfonter.org/

@font-face {
  font-family: 'Roboto';
  src:url('Roboto-Black.woff') format('woff'),
  url('Roboto-Black.woff2') format('woff2');

  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src:url('Roboto-BlackItalic.woff') format('woff'),
  url('Roboto-BlackItalic.woff2') format('woff2');

  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src:url('Roboto-Bold.woff') format('woff'),
  url('Roboto-Bold.woff2') format('woff2');

  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src:url('Roboto-BoldItalic.woff') format('woff'),
  url('Roboto-BoldItalic.woff2') format('woff2');

  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src:url('Roboto-Medium.woff') format('woff'),
  url('Roboto-Medium.woff2') format('woff2');

  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src:url('Roboto-MediumItalic.woff') format('woff'),
  url('Roboto-MediumItalic.woff2') format('woff2');

  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src:url('Roboto-Regular.woff') format('woff'),
  url('Roboto-Regular.woff2') format('woff2');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src:url('Roboto-Italic.woff') format('woff'),
  url('Roboto-Italic.woff2') format('woff2');

  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src:url('Roboto-Light.woff') format('woff'),
  url('Roboto-Light.woff2') format('woff2');

  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src:url('Roboto-LightItalic.woff') format('woff'),
  url('Roboto-LightItalic.woff2') format('woff2');

  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src:url('Roboto-Thin.woff') format('woff'),
  url('Roboto-Thin.woff2') format('woff2');

  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src:url('Roboto-ThinItalic.woff') format('woff'),
  url('Roboto-ThinItalic.woff2') format('woff2');

  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
