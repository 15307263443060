.alertWrapper {
  margin-bottom: 16px;
}

.text {
  text-align: center;
  margin-bottom: 27px;
  display: block;
}

.btn {
  margin-top: 12px;
}

