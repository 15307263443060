@import "src/resource/style/variables";

.userName {
  font-weight: 600;
  font-size: 16px;
}

.secondary {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: $color-secondary-text;
}
