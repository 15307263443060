.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100%;
  //background: #fff;
  position: relative;
}
