.wrap {
  display: none;
  position: fixed;
  z-index: 9999;

  &.active {
    display: block;
  }
}

.btn {
  position: fixed;
  right: 24px;
  top: 32px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, .10);
  box-shadow: none;
  font-size: 16px;
  border: none;
  width: 42px !important;
  height: 42px !important;
  color: #fff;

  &:not([disabled]):hover {
    color: #fff !important;
  }

  &.left,
  &.right {
    top: 50%;
    transform: translateY(-50%);
  }

  &.left {
    right: auto;
    left: 24px;
  }
}
