.collapseHeader {
  display: flex;
  align-items: center !important;
}

.addBtn {
  max-width: 200px;
  margin-top: 16px;
  margin-bottom: 12px;
}
