.inner {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 24px;

  .inputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 12px;

    .divider {
      grid-column-start: 1;
      grid-column-end: 3;
      margin: 28px 0;
    }

    > div {
      display: grid;
      grid-auto-flow: row;
      grid-gap: 12px;
    }
  }
}
