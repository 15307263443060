@import "src/resource/style/variables";

.container {
  border-radius: 14px;
  background-color: #fff;
  width: 560px;
  margin: 0 auto;
  overflow: hidden;

  .content {
    padding: 24px;
  }

  .label {
    margin-bottom: 5px;
  }
}

.secondImage {
  .imageContainer, .overlay:before {
    width: 560px !important;
    height: 220px !important;
    border-radius: 0;
  }

  .uploadedImg {
    object-fit: cover;
  }

  .upload {
    & div {
      width: 560px !important;
      height: 220px !important;
      border-radius: 0 !important;
    }
  }
}
