@import "src/resource/style/variables";

.flexRow {
  display: flex;
  gap: 8px;
}

.item {
  width: 100%;

  .content {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 24px;

    align-items: center;
  }

  .actions {
    display: grid;
    grid-gap: 12px;
    justify-content: flex-end;
    grid-template-columns: auto auto;
  }

  .inner {
    align-self: flex-end;
    display: grid;
    grid-auto-flow: row;
    grid-gap: 12px;
    padding: 12px 0;

    .additionalInfo {
      display: grid;
      gap: 12px;
      grid-template-columns: 1fr 80px; // calc(((32px + 12px) * 3) - 12px);
    }

    input, span {
      color: $color-text;
    }
  }
  .audioFile {
    display: flex;
    align-items: center;
    width: 100%;

    background-color: #f2f3f4;
    border: 1px solid $border-color;
    border-radius: 6px;

    .audioLangTag {
      flex-wrap: nowrap;
      white-space: nowrap;
      color: rgba(0, 0, 0, 0.25);
      height: 100%;
      line-height: 32px;
      text-align: center;
      padding: 0 11px;
      border-right: 1px solid lightgrey;
    }

    > audio {
      width: 100%;
      height: 32px;
    }
  }

  &:after {
    content: '';
    display: block;
    height: 1px;
    margin: 25px 0;
    background-color: $color-line;
  }

  &:last-child {
    padding-bottom: 0;
  }
}
