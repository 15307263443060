@import "src/resource/style/variables";

.container {
  display: flex;
  flex-direction: column;

  .label {
    margin-bottom: 12px;
  }

  .weekSelector {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 6px;
    padding: 5px 12px;
    text-align: left;
    cursor: pointer;
    transition: all .2s;

    &:hover {
      background-color: $color-line;
    }

    &.active {
      color: $white;
      background-color: $primary-bg-active-color;
    }
    &.disabled {
      cursor: not-allowed;
      color: rgba(0, 0, 0, 0.25);
    }
  }

  .pdfMark {
    line-height: normal;

    .icon {
      font-size: 18px;
    }
  }
}
