.empty {
  > div > svg {
    width: 185px;
  }
}

.item {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;

  > div:first-child {
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.45);
  }
}


