.linkButton {
  //height: 22px;
  padding: 4px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  transition: .2s;
  max-height: 32px;
  width: max-content;

  &:hover {
    background-color: #eee;
  }

  &.disabled {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.25);
    background-color: rgba(0, 0, 0, 0.04);
    cursor: not-allowed;
  }
}
