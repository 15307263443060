@import "src/resource/style/variables";

.wrap {
  text-align: center;
  margin-top: 24px;
  color: $color-secondary-text-2;

  > a {
    color: $color-secondary-text-2;
  }

  .socials {
    display: flex;
    justify-content: center;
    gap: 24px;
    align-items: center;
    margin-bottom: 15px;

    .icon {
      display: flex;
    }
  }

  .menuWrap {
    display: flex;
    justify-content: center;
    gap: 8px;
    align-items: center;
    margin-bottom: 8px;

    > a {
      display: flex;
      align-items: center;
      color: $color-secondary-text-2;

      &:first-child {
        &:after {
          margin-left: 8px;
          content: '|';
          display: block;
        }
      }
    }
  }
}
