.videoContainer {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 24px;

  .videoInput {
    flex-grow: 1; //width: 100%;
  }
}
