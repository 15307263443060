@import "src/resource/style/variables";

$gap-step: 12px;
$grid-actions-count: 2;

.recipe {
  padding-bottom: 24px;
  width: 100%;

  .content {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 24px;

    .imageContainer {
      box-sizing: border-box;
      border: 1px solid #d9d9d9;
      border-radius: 8px;
      height: 144px;
      width: 144px;
    }
  }

  .actions {
    display: grid;
    grid-gap: 12px;
    justify-content: flex-end;
    grid-template-columns: repeat($grid-actions-count, auto);
  }

  .inner {
    align-self: flex-end;
    display: grid;
    grid-auto-flow: row;
    grid-gap: $gap-step;
    padding: 12px 0;

    .additionalInfo {
      display: grid;
      gap: $gap-step;
      grid-template-columns: repeat(3, 1fr) auto auto calc((32px + $gap-step) * $grid-actions-count - $gap-step);

      &.withPortions {
        grid-template-columns: repeat(4, 1fr) auto auto calc((32px + $gap-step) * $grid-actions-count - $gap-step);
      }
    }

    input, span {
      color: $color-text;
    }
  }

  &:last-child {
    padding-bottom: 0;

    &:after {
      content: '';
      display: block;
      height: 1px;
      margin: 25px 0;
      background-color: $color-line;
    }
  }
}
