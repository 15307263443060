@import "src/resource/style/variables";

.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg-color-content;
  font-size: 36px;
  height: 100%;
  overflow: hidden;

  > span {
    opacity: 0.45;
  }

  &.large {
    font-size: 36px;
  }
}
