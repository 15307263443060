@import "src/resource/style/variables";

.imagesWrap {
  border-radius: 6px;
  border: 1px solid $border-color;
  padding: 16px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;

  .imageBlock {
    display: flex;
    gap: 16px;
    align-items: center;
  }
}
