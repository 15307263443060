$video-container-size: 144px;
//$wide-image-container-size: 254px;

.uploader {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;

  &.singleUpload {
    gap: 0;
  }

  &.H170 {
    .imageContainer, .overlay:before {
      height: 170px !important;
    }
    .upload {
      & div {
        height: 170px !important;
      }
    }
  }

  &.H220 {
    .imageContainer, .overlay:before {
      height: 220px !important;
    }
    .upload {
      & div {
        height: 220px !important;
      }
    }
  }

  .imageContainer {
    box-sizing: border-box;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    height: $video-container-size;
    width: $video-container-size;
  }
  .overlay {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.45);
      width: 100%; // $image-container-size;
      height: 100%; // $image-container-size;
      z-index: 1;
      transition: all 0.3s;
      content: " ";
      opacity: 0;
    }

    .contentInOverlay {
      display: flex;
      gap: 6px;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      opacity: 0;
      transition: all 0.3s;

      color: rgba(255, 255, 255, 0.65);
      font-size: 16px;
      padding: 2px;

      .btn {
        transition: all 0.2s;
        padding: 1px;
        border-radius: 4px;

        &:hover {
          color: white;
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }

    &:hover {
      &:before, .contentInOverlay {
        opacity: 1;
      }
    }
  }

  .upload {
    width: auto !important;

    & div {
      width: $video-container-size !important;
      height: $video-container-size !important;
      margin-bottom: 0 !important;
      margin-right: 0 !important;
    }
  }

  .uploadedVid {
    height: 100%;
    width: 100%;
    position: relative;

    object-fit: contain;
  }
}

.uploadBtn {
  height: $video-container-size;
  width: $video-container-size;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hiddenUpload {
  display: none;
}

.modal {
  > div {
    width: max-content;
    max-width: 100%;
    background-color: transparent !important;
    padding: 0 !important;
    margin: 0 auto;
    box-shadow: none !important;
  }

  .modalContent {
    overflow: hidden;
    max-height: 94vh;

    > video {
      display: block;
      margin: 0 auto;
      max-height: 94vh;
      height: auto;
      width: 100%;
    }
  }
}

.btnWrap {
  display: none;
  position: fixed;
  z-index: 9999;

  &.active {
    display: block;
  }

  .btn {
    position: fixed;
    right: 24px;
    top: 32px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .10);
    box-shadow: none;
    font-size: 16px;
    border: none;
    width: 42px !important;
    height: 42px !important;
    color: #fff;

    &:not([disabled]):hover {
      color: #fff !important;
    }
  }
}
