.dualButton {
  display: flex;
  flex-direction: column;
  gap: 0;

  .button {
    height: 16px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &.first {
      border-radius: 6px 6px 0 0;
    }
    &.second {
      border-radius: 0 0 6px 6px;
    }
  }
}
