@import 'src/resource/style/variables';

.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow: auto;
  height: calc(100% - 54px); // 54 logout btn height

  :global {
    .ant-menu {
      padding: 4px 0;
      line-height: 40px;

      .ant-menu-item-selected,
      .ant-menu-item:not(.ant-menu-item-selected):active {
        color: $color-text-light-solid !important; // changes color when hover + click
        background: $primary-bg-active-color !important;
      }

      .ant-menu-submenu-title {
        padding: 0 8px 0 16px;
      }

      .icon-padding-mini,
      .icon-padding-mini-sub > * {
        padding: 0 0 0 12px;
      }

      .ant-menu-overflow-item {
        .ant-menu-submenu-title {
          //padding: 0 16px;
        }
      }

      .ant-menu-submenu {
        margin: 0;
        padding: 0;
      }

      .ant-menu-item,
      .ant-menu-submenu {
        height: 40px;
        border-radius: 8px;
        transition: all .2s !important;

        & a {
          text-decoration: none;
        }

        * {
          transition: color 0s !important;
        }

        &.ant-menu-item-selected,
        &.ant-menu-item-active,
        &.ant-menu-submenu-selected svg,
        &.ant-menu-submenu-open svg {
          /** changes styles when item active or hovered. example: color */
        }

        &.ant-menu-item-selected,
        &.ant-menu-submenu-selected {
          //color: $color-text-light-solid !important; /** changes color when item selected */
          //font-weight: 600;
        }

        &::after,
        &:hover::after {
          border-bottom-width: 0;
        }
      }
    }
  }
}

.rightMenu {
  :global {
    .ant-menu-title-content {
      margin-inline-start: 0px !important;
    }
  }
}

.clientLogo {
  width: 100%;
  max-width: 130px;
  height: 30px;
  object-fit: contain;
}

.clientLogoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-left: 24px;
}
