@import "src/resource/style/variables";

.card {
  border: none;
  background-color: $bg-color-secondary-content;
}

.picker {
  visibility: hidden;
  transform: translateY(-35px);
}

.container {
  display: grid;
  grid-template-columns: 290px 1fr;
  align-items: flex-start;
  grid-gap: 25px;
  min-height: calc(335px + 24px);
  z-index: 1;
  padding: 24px;
  position: relative;
  border-radius: 8px;
  background-color: $bg-color-secondary-content;
}

.weekDays {
  align-self: flex-end;
}

.days {
  margin-top: 8px;
  padding: 2px;
  border-radius: 6px;
  display: flex;
  gap: 2px;
  background-color: $bg-color-secondary-content-2;

  .day {
    border: none;
    width: 90px;
    box-shadow: none;

    &:not(.selectedDay):not(:hover) {
      background-color: transparent;
    }
  }
}

.currentDay {
  border: 1px solid $primary-color;
}


