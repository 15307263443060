.statusBadge {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;

  background: rgba(0, 0, 0, 0.04);
  border: 1px solid #d9d9d9;
  padding: 0 12px;
  //height: 32px;
  //width: 32px;
}
