@import "src/resource/style/variables";
@import "src/resource/font/stylesheet";

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: 'Arial', serif;
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration: underline;
  //text-decoration: none;
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

html, body {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
}

.ant-form-item-label {
  font-weight: 400 !important;
}

.ant-pro-card {
  .ant-pro-card-body {
    padding: 0 24px;
    margin: 0;

    .pro-table-checkbox-text {
      display: none;
    }

    .ant-pro-table-list-toolbar-container {}

    .ant-pro-table-list-toolbar-title {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }

    .ant-table-wrapper {}
  }
}

.ant-radio-group {
  background-color: $bg-color-secondary-content-2;
  border-color:  $bg-color-secondary-content-2 !important;
  border-radius: 4px;
  padding: 2px;

  .ant-radio-button-wrapper {
    width: 142px;
    text-align: center;
    border-radius: 4px;
    background-color: $bg-color-secondary-content-2;
    border-color:  $bg-color-secondary-content-2 !important;

    &.ant-radio-button-wrapper-checked {
      background-color: #fff !important;
      border-color: $bg-color-secondary-content-2 !important;

      &:before {
        content: none;
      }
    }
  }
}

.ant-pro-table-column-setting {
  &-overlay {
    .pro-table-checkbox {
      display: none;
    }
  }
  &-list-item-title {
    max-width: 100px;
  }
}

.ant-table {
  &-tbody {
    .ant-table-row:hover {
      .ant-table-cell {
        background: $grey-1;
      }
    }
  }
}

#root {
  min-height: 100vh;
  min-width: 720px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;

  .ant-app {
    width: 100%;
  }

  > * {
    max-width: 2560px;
    box-shadow: 0 0 50px #eee;
  }
}

.ant-input-prefix {
  svg {
    color: $neutral-secondary;
  }
}

.ant-pro-table-list-toolbar-right {
  margin-left: 16px;
  gap: 16px;
}

.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #eee;
}

.ant-badge-status-text {
  text-transform: capitalize;
}

.ant-badge {
  white-space: nowrap;
}

.ant-pro-query-filter-row-split {
  .ant-form-item-label {
    //flex: 0 0 100px !important; // TODO check, maybe increase size of filters label
    // maybe change 100px to max-content
    & label {
      @extend .clip;
      line-height: 32px;
    }
  }
}

.ant-form-item-label {
  font-weight: 600;
}

.ant-form-item-explain {
  &:first-letter {
    text-transform: uppercase;
  }
}

.ant-form-item {
  &-has-error {
    .ant-input-prefix {
      & svg {
        color: $error-color;
      }
    }
  }
}

.ant-select {
  .ant-select-arrow {
    inset-inline-end: 9px;
  }
  &-item-option {
    &-selected {
      color: $white !important;
      & span { color: $white !important; }
    }
  }
}

.ant-pro-table-list-toolbar {
  .ant-pro-table-list-toolbar-container {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr auto;

    .ant-pro-table-list-toolbar-left,
    .ant-pro-table-list-toolbar-title {
      width: 100%;
      max-width: 100%;
    }
  }
}

.ant-collapse-content-box .ant-table-wrapper {
  border-top: none !important;
  padding: 0 !important;
}

.ant-pagination.ant-table-pagination {
  padding: 0 16px 16px;
}

.ant-pro-table-column-setting-list-item-title {
  white-space: nowrap;
}

.ant-pro-table {
  &.mb-20 {
    .ant-pro-card {
      padding-bottom: 24px !important;
    }
  }

  &.search-hidden {
    .ant-pro-table-search-query-filter {
      display: none;
    }
  }
}

.ant-picker-calendar {
  .ant-picker-calendar-date {
    padding-top: 0 !important;

    .ant-picker-calendar-date-content {
      height: 100px;

      ul > li {
        height: 20px;

        > span {
          height: 20px;
          line-height: 18px;
        }
      }
    }
  }
}

.ant-menu-vertical {
  .ant-menu-item {
    /** Centering items in vertical menu */
    display: flex;
    align-items: center;
  }
}

.ant-input-affix-wrapper {
  .ant-input-prefix {
    margin-inline-end: 8px;
  }
}

.ant-btn-link {
  > span {
    text-decoration: underline;
  }
}

.ant-breadcrumb {
  &.page-header-breadcrumb {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.ant-select-disabled {
  .ant-badge-status-text {
    color: rgba(0, 0, 0, 0.25) !important;
  }
}

.ant-card-head {
  border: none !important;
}

.ant-card-head-title {
  overflow: visible !important;
}

.weekPickerDropdown {
  box-shadow: none;
  z-index: 999;

  &.unsavedChanges {
    .ant-picker-header,
    .ant-picker-week-panel-row:not(.ant-picker-week-panel-row-selected) {
      pointer-events: none !important;
    }
  }

  .ant-picker-panel-container {
    box-shadow: none;
  }

  .ant-picker-week-panel-row-selected {
    .ant-picker-cell {
      &:before {
        background-color: $secondary-color !important;
      }

      .ant-picker-cell-inner:not(.active) {
        color: $color-text !important;
      }
    }
  }

  .ant-picker-week-panel {
    .ant-picker-week-panel-row {
      .ant-picker-cell {
        .ant-picker-cell-inner {
          &.active {
            background: $primary-bg-active-color !important;
            color: #fff;
            border-radius: 6px;
          }
        }
      }
    }
  }
}

.ant-table-row-selected {
  .ant-table-cell {
    background: transparent !important;
  }
}

.reviews-table {
  .ant-table-cell {
    vertical-align: top;

    &.ant-table-cell-row-hover {
      background-color: #fff !important;
    }
  }
}

.text-centered {
  text-align: center;
}

.clip {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.truncateDefault {
  display: -webkit-box;
  //-webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: unset;
  overflow: hidden;
}

.truncate {
  @extend .truncateDefault;
  -webkit-line-clamp: 2;
}

.truncate3 {
  @extend .truncateDefault;
  -webkit-line-clamp: 3;
}

.cursor-pointer {
  cursor: pointer;
}

.with-hover {
  &:hover {
    background: $grey-1;
  }
}

.link-no-underline,  {
  text-decoration: none;

  &:hover, &:focus, &:active {
    text-decoration: none;
  }
}

.no-space-form-item {
  margin-bottom: 0;
  min-height: 0 !important;
  & div {
    min-height: 0 !important;
  }
}

.hidden-form-item {
  margin: 0 !important;
  height: 0 !important;
  width: 0 !important;
}

.with-error {
  color: $error-color;
}

.card-no-styles {
  background-color: transparent;
  margin: 0;
  border: none;
  padding: 0;
}

/** Image Uploader wrapper styles */
.container-with-image {
  border-radius: 6px;
  border: 1px solid $border-color;
  padding: 16px;
  margin-bottom: 24px;
}

.image-banner-block {
  @extend .container-with-image;
  display: flex;
  gap: 16px;
  align-items: center;
}
.upload-paragraph {
  margin-bottom: 0 !important;
}
.upload-text {
  font-size: 12px;
}

.flex-row-wrapper {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.flex-column-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.required {
  &:before {
    content: "*";
    color: #ff4d4f;
    display: inline-block;
    margin-inline-end: 4px;
    font-size: 14px;
    line-height: 1;
  }
}

.collapse-marathons {
  border-radius: 0;

  .ant-collapse-item {
    border-bottom: 1px solid $color-line; // #d9d9d9

    .ant-collapse-header {}

    .ant-collapse-content {
      padding-left: 56px;
      &-active {}
      &-box {
        .ant-list {
          &-items {
            .ant-list-item {
              padding-right: 0;
            }
          }
        }
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.modal-with-table {
  .ant-pro-table-search {
    margin-top: 24px;

    form {
      border: 1px solid $color-line;
      border-radius: 6px;
    }
  }

  .ant-pro-card-body {
    padding: 0;
    overflow-x: auto;
  }
}

.week-day-picker-container {
  display: grid;
  grid-template-columns: 240px 1fr;
  align-items: flex-start;
  grid-gap: 25px;
  z-index: 1;
  padding: 24px;
  border-radius: 8px;
  background-color: $bg-color-secondary-content;
  margin-bottom: 24px;
}

.modal-content-centered {
  .ant-modal-content {
    width: max-content;
    max-width: 100%;
    background-color: transparent !important;
    padding: 0 !important;
    margin: 0 auto;
    box-shadow: none !important;
  }
  .ant-modal-body {
    width: 100%;
    height: 100%;
  }
}

.video-play-icon {
  position: absolute;
  color: #fff;
  top: 50%;
  font-size: 36px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.table-hide-filters {
  .ant-pro-table-search-query-filter {
    display: none !important;
  }
}
