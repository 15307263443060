.uploader {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;

  .upload {
    width: auto !important;
  }
}

.audioContainer {
  box-sizing: border-box;

  .audioFile {
    width: 100%;
  }
}
